/* eslint-disable complexity */
/* eslint-env browser */
import React from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "../../components/hooks/useEnvironmentVariables.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import LinkifyText from "../../shared/react/LinkifyText.jsx";
import PhysicianAutocompleteInput from "./PhysicianAutocompleteInput.jsx";

function PhysicianInfo({
  // Props
  readOnly = false,
  study = {},
  requiredFields,
}) {
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control} = useFormContext();

  return (
    <Grid container spacing={2} alignItems="flex-start">
      {/* NAME */}
      <Grid item xs={12} sm={5}>
        {readOnly && (
          <Typography variant="body2" data-cy="physician-name-value">
            <b>Name</b>: {study.studyDetails?.physicianName}
          </Typography>
        )}

        {!readOnly && features.physicianNpiNumber && (
          <PhysicianAutocompleteInput
            name="physicianName"
            label="Name"
            defaultValue={study.studyDetails?.physicianName || ""}
            required={requiredFields?.physicianName}
            rules={{
              ...(requiredFields?.physicianName && {required: "Name is required"}),
            }}
            data-cy="physician-name-input"
          />
        )}

        {!readOnly && !features.physicianNpiNumber && (
          <FormStringInput
            disabled={readOnly}
            required={requiredFields?.physicianName}
            control={control}
            defaultValue={study.studyDetails?.physicianName || ""}
            label="Name"
            name="physicianName"
            id="physician-name-input"
            rules={{
              ...(requiredFields?.physicianName && {required: "Name is required"}),
            }}
          />
        )}
      </Grid>

      {features.physicianNpiNumber && (
        <>
          {/* NPI NUMBER */}
          <Grid item xs={12} sm={3}>
            {readOnly && (
              <Typography variant="body2" data-cy="physician-npi-number-value">
                <b>NPI Number</b>: {study.studyDetails?.physicianNpiNumber}
              </Typography>
            )}

            {!readOnly && (
              <PhysicianAutocompleteInput
                name="physicianNpiNumber"
                label="NPI Number"
                defaultValue={study.studyDetails?.physicianNpiNumber || ""}
                required={requiredFields?.physicianNpiNumber}
                rules={{
                  ...(requiredFields?.physicianNpiNumber && {required: "NPI number is required"}),
                }}
                data-cy="physician-npi-number-input"
              />
            )}
          </Grid>

          {/* TYPE */}
          <Grid item xs={12} sm={4}>
            {readOnly && (
              <Typography variant="body2" data-cy="physician-type-value">
                <b>Type</b>: {study.studyDetails?.physicianType}
              </Typography>
            )}

            {!readOnly && (
              <FormStringInput
                disabled={readOnly}
                required={requiredFields?.physicianType}
                control={control}
                defaultValue={study.studyDetails?.physicianType || ""}
                label="Type"
                name="physicianType"
                data-cy="physician-type-input"
                rules={{
                  ...(requiredFields?.physicianType && {required: "Type is required"}),
                }}
              />
            )}
          </Grid>
        </>
      )}

      {/* FACILITY */}
      <Grid item xs={12} sm={5}>
        {readOnly && (
          <Typography variant="body2" data-cy="physician-facility-value">
            <b>Facility</b>: {study.studyDetails?.physicianFacility}
          </Typography>
        )}

        {!readOnly && (
          <FormStringInput
            disabled={readOnly}
            required={requiredFields?.physicianFacility}
            control={control}
            defaultValue={study.studyDetails?.physicianFacility || ""}
            label="Facility"
            name="physicianFacility"
            data-cy="physician-facility-input"
            rules={{
              ...(requiredFields?.physicianFacility && {required: "Facility is required"}),
            }}
          />
        )}
      </Grid>

      {/* PHONE NUMBER */}
      <Grid item xs={12} sm={3}>
        {readOnly && (
          <Typography variant="body2" data-cy="physician-phone-number-value">
            <b>Phone</b>: {study.studyDetails?.physicianPhoneNumber}
          </Typography>
        )}

        {!readOnly && (
          <FormStringInput
            disabled={readOnly}
            required={requiredFields?.physicianPhoneNumber}
            control={control}
            defaultValue={study.studyDetails?.physicianPhoneNumber || ""}
            label="Phone Number"
            name="physicianPhoneNumber"
            data-cy="physician-phone-number-input"
            rules={{
              validate: {
                isValidPhone: (phone) =>
                  !phone || isMobilePhone(phone) ? true : "Phone must be a valid phone number",
              },
              ...(requiredFields?.physicianPhoneNumber && {required: "Phone number is required"}),
            }}
          />
        )}
      </Grid>

      {/* EMAIL */}
      <Grid item xs={12} sm={4}>
        {readOnly && (
          <Typography variant="body2" data-cy="physician-email-value">
            <b>Email</b>: {study.studyDetails?.physicianEmail}
          </Typography>
        )}

        {!readOnly && (
          <FormStringInput
            disabled={readOnly}
            required={requiredFields?.physicianEmail}
            control={control}
            defaultValue={study.studyDetails?.physicianEmail || ""}
            label="Email"
            name="physicianEmail"
            data-cy="physician-email-input"
            rules={{
              ...(requiredFields?.physicianEmail && {required: "Email is required"}),
            }}
          />
        )}
      </Grid>

      {/* ADDRESS */}
      <Grid item xs={12}>
        {readOnly && (
          <Typography variant="body2" data-cy="physician-address-value">
            <b>Address</b>: {study.studyDetails?.physicianAddress}
          </Typography>
        )}

        {!readOnly && (
          <FormStringInput
            disabled={readOnly}
            required={requiredFields?.physicianAddress}
            control={control}
            defaultValue={study.studyDetails?.physicianAddress || ""}
            label="Address"
            name="physicianAddress"
            data-cy="physician-address-input"
            otherProps={{multiline: true, maxRows: 4}}
            rules={{
              ...(requiredFields?.physicianAddress && {required: "Address is required"}),
            }}
          />
        )}
      </Grid>

      {/* NOTIFICATION CRITERIA */}
      <Grid item xs={12}>
        {readOnly && (
          <Typography variant="body2" data-cy="physician-notes-value">
            <b>Notification Criteria</b>: <LinkifyText text={study.studyDetails?.physicianNotes} />
          </Typography>
        )}

        {!readOnly && (
          <FormStringInput
            disabled={readOnly}
            required={requiredFields?.physicianNotes}
            control={control}
            defaultValue={study.studyDetails?.physicianNotes || ""}
            label="Notification Criteria"
            name="physicianNotes"
            data-cy="physician-notes-input"
            rules={{
              ...(requiredFields?.physicianNotes && {required: "Notification criteria is required"}),
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

PhysicianInfo.propTypes = {
  readOnly: PropTypes.bool,
  study: PropTypes.object,
  requiredFields: PropTypes.object.isRequired,
};

export default PhysicianInfo;
