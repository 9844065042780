import angular from "angular";

import {angularizeDirective} from "../../../shared/react-in-angular.js";
import NewStudyWrapper from "./NewStudyWrapper.jsx";

export default angular.module("app.dialogs.newStudy", []).name;

// matches <br-new-study>
angularizeDirective(NewStudyWrapper, "brNewStudy", angular.module("app.dialogs.newStudy"), {
  device: "=",
});
