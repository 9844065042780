/* eslint-disable complexity */
/* eslint-env browser */
import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import AlertTitle from "@mui/material/AlertTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "../../../components/hooks/useEnvironmentVariables.jsx";
import Alert from "../../../shared/react/Alert.jsx";

function Authorization({
  // Props
  device,
  facility,
  facilities,
  requiredFields,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control, watch} = useFormContext();
  const watchStudyType = watch("studyType");

  //---------------------------------------------------------------------------
  // Determine which authorization fields to display
  //---------------------------------------------------------------------------
  const downgradeAuthNeeded = React.useMemo(() => {
    return (
      features.downgradeAuthorized &&
      (watchStudyType.startsWith("mct") || watchStudyType.toLowerCase().includes("extendedholter")) &&
      requiredFields?.downgradeAuthorized
    );
  }, [features.downgradeAuthorized, requiredFields?.downgradeAuthorized, watchStudyType]);

  const downgradeAuthGivenMessage = React.useMemo(() => {
    // MCT downgrade auth message
    if (watchStudyType.startsWith("mct")) {
      return `In the event of insurance coverage denial, I agree to allow the study type to be downgraded
        to a CEM study in order to allow a report to be provided to the patient's ordering provider.`;
    }

    // Extended Holter downgrade auth message
    return `If the total recording duration does not meet the minimum requirements for the prescribed
      study, I agree to allow the study to be downgraded to a lower duration, and a report should be
      generated for the patient's ordering provider.`;
  }, [watchStudyType]);

  const downgradeAuthRevokedMessage = React.useMemo(() => {
    // MCT downgrade auth message
    if (watchStudyType.startsWith("mct")) {
      return `In the event of insurance coverage denial, I DO NOT agree to allow the study type to be downgraded
        to a CEM. The patient or facility will be responsible for charges associated with the MCT regardless
        of insurance coverage.`;
    }

    // Extended Holter downgrade auth message
    return `If the total recording duration does not meet the minimum requirements for the prescribed study,
      I DO NOT agree to allow the study to be downgraded to a lower duration. A report should NOT be generated
      for the patient's ordering provider.`;
  }, [watchStudyType]);

  const followUpAuthNeeded = React.useMemo(() => {
    return features.followUpStudy && watchStudyType?.toLowerCase().includes("wireless");
  }, [features.followUpStudy, watchStudyType]);
  const authorizedFacility = React.useMemo(() => {
    return facility.billingFacility?.name || facilities[facility.parentFacilityId]?.name || facility.name;
  }, [facilities, facility.billingFacility?.name, facility.name, facility.parentFacilityId]);

  return (
    <Grid container columnSpacing={4} rowSpacing={2}>
      <Grid item xs={12}>
        <Alert
          level="info"
          message="The following BitRhythm features require prior authorization in order to be enabled for this study."
        />
      </Grid>

      {downgradeAuthNeeded && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" mb={1}>
            <b>Downgrade Authorization</b>
          </Typography>
          <Controller
            control={control}
            name="downgradeAuthorized"
            shouldUnregister
            defaultValue="false"
            render={({field: {onChange, value}}) => (
              <RadioGroup name="downgradeAuthorized" value={value} onChange={onChange}>
                <FormControlLabel
                  value="true"
                  sx={{mb: 1, mr: 0, alignItems: "start"}}
                  control={<Radio size="small" />}
                  label={
                    <Alert
                      level="success"
                      message={
                        <>
                          <AlertTitle>Yes</AlertTitle>
                          {downgradeAuthGivenMessage}
                        </>
                      }
                    />
                  }
                  data-cy="downgrade-authorized-true"
                />

                <FormControlLabel
                  value="false"
                  sx={{mr: 0, alignItems: "start"}}
                  control={<Radio size="small" />}
                  label={
                    <Alert
                      level="warning"
                      message={
                        <>
                          <AlertTitle>No</AlertTitle>
                          {downgradeAuthRevokedMessage}
                        </>
                      }
                    />
                  }
                  data-cy="downgrade-authorized-false"
                />
              </RadioGroup>
            )}
          />
        </Grid>
      )}

      {followUpAuthNeeded && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" mb={1}>
            <b>Prior Authorization for Follow-up Study</b>
          </Typography>
          <Controller
            control={control}
            name="priorAuthorization"
            shouldUnregister
            defaultValue="false"
            render={({field: {onChange, value}}) => (
              <RadioGroup name="priorAuthorization" value={value} onChange={onChange}>
                <FormControlLabel
                  value="true"
                  sx={{mb: 1, mr: 0, alignItems: "start"}}
                  control={<Radio size="small" />}
                  label={
                    <Alert
                      level="success"
                      message={
                        <>
                          <AlertTitle>Yes</AlertTitle>
                          This criteria authorizes {authorizedFacility} to enact a proactive care study if a
                          patient&apos;s Holter monitor is non-diagnostic based on the arrhythmia criteria
                          outlined for the study. If the study is unremarkable per this arrhythmia criteria
                          then the study will be optimized to the specified test for the duration specified in
                          written physician orders. A comprehensive report will then be provided to the
                          ordering physician.
                        </>
                      }
                    />
                  }
                  disabled={!!device?.formatted}
                  data-cy="prior-authorization-true"
                />

                <FormControlLabel
                  value="false"
                  sx={{mr: 0, alignItems: "start"}}
                  control={<Radio size="small" />}
                  label={
                    <Alert
                      level="warning"
                      message={
                        <>
                          <AlertTitle>No</AlertTitle>A follow-up study is not required.
                        </>
                      }
                    />
                  }
                  data-cy="prior-authorization-false"
                />
              </RadioGroup>
            )}
          />
        </Grid>
      )}
    </Grid>
  );
}

Authorization.propTypes = {
  device: PropTypes.object,
  facility: PropTypes.object,
  facilities: PropTypes.object,
  requiredFields: PropTypes.object,
};

export default Authorization;
